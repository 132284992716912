import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import React, { memo } from 'react'

export interface Props {
  cta?: ButtonProps
  description?: string
  images: ImageProps[]
  title?: string
}

export const Stripe = memo(function Stripe({
  cta,
  description,
  images,
  title,
}: Props) {
  return (
    <Container dial={4} row stretch wrap>
      <MediaContextProvider>
        <LeftSide dial={4}>
          <Wrapper>
            {title ? (
              <FadeInUp>
                <Title>{title}</Title>
              </FadeInUp>
            ) : null}
            {description ? (
              <FadeInUp>
                <Description
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </FadeInUp>
            ) : null}
            {cta ? (
              <FadeInUp>
                <CTA variant="simple" {...cta} />
              </FadeInUp>
            ) : null}
          </Wrapper>
        </LeftSide>
        <RightSide row>
          <Background>{images[0] ? <Image {...images[0]} /> : null}</Background>
          <Media greaterThanOrEqual="ipadHorizontal">
            <Background>
              {images[1] ? <Image {...images[1]} /> : null}
            </Background>
          </Media>
        </RightSide>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin-top: 9rem;
  padding: 0 6.944vw 0 5vw;
  position: relative;
  &:nth-of-type(even) {
    padding-right: 5vw;
    padding-left: 6.944vw;
    > div {
      &:first-of-type {
        order: 2;
        > div {
          padding-right: 7.639vw;
          padding-left: 5.417vw;
        }
      }
      &:last-of-type {
        transform: translateX(5.694vw);
      }
    }
  }

  @media (max-width: 1199px) {
    padding: 0;
    &:nth-of-type(even) {
      padding: 0;
      > div {
        &:first-of-type {
          > div {
            padding-right: 1.875rem;
            padding-left: 1.875rem;
          }
        }
        &:last-of-type {
          transform: translateX(1.875rem);
        }
      }
    }
  }

  @media (max-width: 1023px) {
    margin-top: 4.5rem;
  }

  @media (max-width: 767px) {
    margin-top: 3.75rem;
    &:first-of-type {
      margin-top: 0;
    }
    &:nth-of-type(even) {
      > div {
        &:first-of-type {
          > div {
            padding-right: 1.25rem;
            padding-left: 1.25rem;
          }
        }
        &:last-of-type {
          transform: translateX(0);
        }
      }
    }
  }
`

const LeftSide = styled(FlexBox)`
  width: 40%;
  padding: 3.75rem 0;

  @media (max-width: 1023px) {
    width: 50%;
  }

  @media (max-width: 767px) {
    width: 100%;
    order: 2;
    padding: 0;
  }
`

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight4};
  padding: 3.75rem 5.417vw 3.75rem 7.639vw;
  position: relative;
  z-index: 1;

  @media (max-width: 1199px) {
    padding: 3.75rem 1.875rem;
  }

  @media (max-width: 767px) {
    padding: 2.25rem 1.25rem 0;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  letter-spacing: -0.0313rem;
  line-height: 2.25rem;
  margin-top: 1.25rem;
`

const CTA = styled(Button)`
  margin-top: 2.5rem;
`

const RightSide = styled(FlexBox)`
  width: 60%;
  min-height: 64vh;
  position: relative;
  transform: translateX(-5.694vw);

  @media (max-width: 1199px) {
    transform: translateX(-1.875rem);
  }

  @media (max-width: 1023px) {
    width: 50%;
  }

  @media (max-width: 767px) {
    width: 100%;
    min-height: 0;
    padding-bottom: 66%;
    transform: translateX(0);
  }
`

const Background = styled.div`
  width: calc(50% - 0.75rem);
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  &:last-of-type {
    right: 0;
    left: auto;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    width: 100%;
  }
`
